<template>
    <v-container fluid>
        <v-card :elevation="1">
            <v-card-text class="py-1">
                <v-row dense>
                    <v-col md="3">
                        <v-combobox
                            label="Shift"
                            dense
                            outlined
                            hide-details
                            clearable
                            :items="shifts"
                            v-model="selectedShift"
                            item-text="name"
                            item-value="id"
                            :loading="shift_loading"
                            @focus="getShifts"
                            class="custom-form"
                        ></v-combobox>
                    </v-col>
                    <v-col md="3">
                        <v-combobox
                            label="Department"
                            dense
                            outlined
                            hide-details
                            clearable
                            :items="$store.getters['department/departments']"
                            v-model="selectedDepartment"
                            item-text="name"
                            item-value="id"
                            :loading="$store.getters['department/loadingDepartments']"
                            @focus="$store.dispatch('department/getDepartments')"
                            class="custom-form"
                        ></v-combobox>
                    </v-col>
                    <v-col md="3">
                        <v-combobox
                            label="Designation"
                            dense
                            outlined
                            hide-details
                            clearable
                            :items="$store.getters['designation/designations']"
                            :loading="$store.getters['designation/loadingDesignations']"
                            @focus="$store.dispatch('designation/getDesignations')"
                            v-model="selectedDesignation"
                            item-text="name"
                            item-value="id"
                            class="custom-form"
                        ></v-combobox>
                    </v-col>
                    
                    <v-col md="3">
                        <v-btn small color="info" height="25" :elevation="1" @click="getEmployees">Search</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>


        <v-row v-if="employees.length > 0">
            <v-col cols="12" class="pb-0"> 
                <v-card :elevation="1">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col md="3">
                                <v-combobox
                                    label="To Shift"
                                    dense
                                    outlined
                                    hide-details
                                    clearable
                                    :items="to_shifts"
                                    v-model="selectedShiftTo"
                                    item-text="name"
                                    item-value="id"
                                    @focus="filterShift"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>

                            <v-col md="3">
                                <v-btn small color="info" height="25" :elevation="1" :loading="loading" @click="transfer">Assign</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" class="pb-0">
                <v-simple-table dense>
                    <template v-slot:default>
                    <thead>
                        <tr>
                            <th>
                                <v-checkbox 
                                    v-model="mark_all"
                                    hide-details 
                                    dense 
                                    class="mt-0"
                                ></v-checkbox>
                            </th>
                            <th class="text-left">Name</th>
                            <th class="text-left">Mobile</th>
                            <th class="text-left">Department</th>
                            <th class="text-left">Designation</th>
                            <th class="text-left">Shift</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="employee in employees"
                        :key="employee.id"
                        >
                            <td>
                                <v-checkbox 
                                    v-model="selectedEmployees"
                                    :value="employee.id"
                                    hide-details 
                                    dense 
                                    class="mt-0"
                                ></v-checkbox>
                            </td>
                            <td>{{ employee.name }}</td>
                            <td>{{ employee.phone }}</td>
                            <td>{{ employee.department_name }}</td>
                            <td>{{ employee.designation_name }}</td>
                            <td>{{ employee.shift_name }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>

  export default {
    data () {
      return {
        shifts: [],
        to_shifts: [],
        selectedShift: null,
        selectedShiftTo: null,
        selectedDepartment: null,
        selectedDesignation: null,
        employees: [],
        shift_loading: false,

        mark_all: false,
        selectedEmployees : [],
        loading: false,
      }
    },
    watch: {
        selectedShift(shift){
            this.clear();
        },
        mark_all(mark){
            this.selectedEmployees = [];
            if(mark){
                this.selectedEmployees = [...new Set(this.employees.map(v => v.id))];
            }
        }
    },
    methods: {
        clear(){
            this.mark_all = false;
            this.selectedShiftTo = null;
            this.selectedEmployees = [];
            this.employees = [];
        },
        async transfer(){
            if(this.selectedShiftTo == null || this.selectedShiftTo.id == undefined){
                alert('Select to shift!');
                return;
            }

            if(this.selectedEmployees.length == 0){
                alert('Select atleast one employee!');
                return;
            }

            this.loading = true;

            let data = {
                shift_to: this.selectedShiftTo.id,
                employees: this.selectedEmployees,
            }

            let resObj = await this.$store.dispatch('shift/shiftManagement', data);

            this.loading = false;
            if(resObj.isSuccess){
                this.clear();
            }
        },
        filterShift(){
            this.to_shifts = [];
            if(this.selectedShift == null || this.selectedShift.id == undefined){
                alert('Select from shift!');
                return;
            }
            this.to_shifts = this.shifts.filter((s) => s.id != this.selectedShift.id);
        },
        async getShifts(){
            this.shift_loading = true;
            await this.$store.dispatch('shift/getShifts');
            this.shifts = this.$store.getters['shift/shifts'];
            this.shift_loading = false;
        },
        async getEmployees() {
            this.clear();
            if(this.selectedShift == null || this.selectedShift.id == undefined){
                alert('Select Shift!');
                return;
            }
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {
                    shiftId: this.selectedShift?.id,
                    departmentId: this.selectedDepartment?.id,
                    designationId: this.selectedDesignation?.id,
                    with_relations : true,
                },
                options: {
                    returnData: true
                }
            });
        },
    },
  }
</script>